<template>
  <div class="ds-wrapper">
    <div class="mb-4 md:mb-6">
      <div class="bg-container-60 py-3 px-4 md:px-8 rounded-t-2xl md-rounded-t-3xl">
        <h4>Payments</h4>
      </div>
      <div class="grid gap-y-1 payment-list">
        <template v-for="(transaction, index) in paymentTransactions">
          <div :class="transaction.showChild ? 'bg-container-80' : 'bg-container-40'" :key="index + 0.1">
            <div :class="`py-6 px-4 md:py-8 md:px-8 ${transaction.hasChild ? 'cursor-pointer' : ''}`" @click="transaction.hasChild ? handleDisplayChild(index) : ''">
              <div class="flex justify-between">
                <div class="grid gap-y-3">
                  <p class="text-body uppercase">{{ transaction.typeText }}</p>
                  <p class="text-primary">{{ transaction.dateFormatted }}</p>
                </div>
                <div class="grid gap-y-3">
                  <div class="flex items-center justify-end gap-x-2">
                    <p class="text-primary text-lg-md">{{ transaction.amountFormatted }}</p>
                    <template v-if="transaction.hasChild" class="cursor-pointer">
                      <vs-icon v-if="!transaction.showChild" icon="expand_more" class="text-body cursor-pointer expand"></vs-icon>
                      <vs-icon v-else icon="expand_less" class="text-body cursor-pointer expand"></vs-icon>
                    </template>
                  </div>
                  <vs-chip :color="getChipColor(transaction.status)" size="x-small" variant="outlined">{{ transaction.statusText }}</vs-chip>
                </div>
              </div>
              <div v-if="transaction.showChild" class="mt-6 h-border"></div>
            </div>
            <template v-if="transaction.showChild">
              <div v-if="transaction.linkedTransactions && transaction.linkedTransactions.length" class="show-transactions grid gap-y-12 md-gap-y-14 pb-8 px-4 md:px-8">
                <template v-for="(linkedTransaction, key) in transaction.linkedTransactions">
                  <div :key="key+0.2">
                    <div class="flex justify-between">
                      <div class="grid gap-y-3 sm:gap-y-4">
                        <p class="text-body">{{ linkedTransaction.dateFormatted }}</p>
                        <div class="flex items-center gap-x-4 md:gap-x-3">
                          <img :src="linkedTransaction.icon" alt="direct-debit" />
                          <p :class="`text-${getChipColor(linkedTransaction.status)}-600 text-ancillary-caps uppercase`">{{ linkedTransaction.typeText }}</p>
                        </div>
                      </div>
                      <div class="grid gap-y-3 sm:gap-y-4">
                        <p class="text-body para-sm-light">ID: {{ linkedTransaction.reference }}</p>
                        <p :class="`text-${getChipColor(linkedTransaction.status)}-600`" class="text-right">{{ linkedTransaction.amountFormatted }}</p>
                      </div>
                    </div>
                    <div class="flex gap-x-2 mt-6" :key="key+0.3" v-if="linkedTransaction.status == 'Processing' && defaultPaymentMethod.type == 'DIRECT_DEBIT'">
                      <TipsIcon />
                      <p class="text-sm text-body">Direct debits can take 2-3 business days</p>
                    </div>
                  </div>
                </template>
              </div>
            </template>
            <vs-button v-if="transaction.paymentButton && defaultPaymentMethod && transaction.showRetryButton" @click="hanldeRetryPayment(transaction, index)" color="primary" class="block w-full max-w-232 mx-auto mt-7 mb-8">{{ transaction.paymentButtonLabel }}</vs-button>
            <div v-if="transaction.paymentButton && transaction.showConfirmButton" class="bg-container-60 pt-8 px-4 md:px-6 pb-6">
              <p class="pt-8 mb-4 text-center">Payment will be deducted from</p>
              <div v-if="defaultPaymentMethod.type == 'CARD'" class="flex flex-row items-center justify-center gap-x-4 text-sm font-normal text-body pt-4">
                <img :src="getCardType(defaultPaymentMethod.card_type)" :alt="defaultPaymentMethod.card_type" />
                <span>**** {{ defaultPaymentMethod.last_four_digits }}</span>
                <span>{{ getExpiredYear({ month: defaultPaymentMethod.month, year: defaultPaymentMethod.year }) }}</span>
              </div>
              <div v-else-if="defaultPaymentMethod.type == 'DIRECT_DEBIT'" class="flex items-center justify-center gap-x-4">
                <img :src="directDebitCard" alt="bank" />
                <p><span class="ml-4 md:ml-6">{{ defaultPaymentMethod.accountName }}</span><span class="ml-4 md:ml-6">{{ getAccountNumber(defaultPaymentMethod.accountNumber) }}</span></p>
              </div>
              <vs-button color="primary" class="block w-full max-w-232 mx-auto mb-4 mt-10" @click="confirmPayment(transaction)">{{ transaction.paymentButtonLabelConfirm }}</vs-button>
              <vs-button type="flat" class="block mx-auto max-w-232" @click="hanldeRetryPayment(transaction, index)">Cancel</vs-button>
            </div>
          </div>
        </template>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions } from "vuex";
import TipsIcon from "../../../../../admin/src/views/components/design-sys-components/icons/TipsIcon.vue";

const directDebitCard = require("@/assets/images/cards/directdebit.svg");
const visaCard = require("@/assets/images/cards/visa.svg");
const masterCard = require("@/assets/images/cards/mastercard.svg");
const amexCard = require("@/assets/images/cards/amex.svg");
const dinersClubCard = require("@/assets/images/cards/dinersclub.svg");
const unionpayCard = require("@/assets/images/cards/unionpay.svg");

export default {
  props:["customerPaymentMethods", "paymentRequest", "reloadContent", "transactions"],

  data() {
    return {
      directDebitCard: directDebitCard,
      visaCard: visaCard,
      masterCard: masterCard,
      amexCard: amexCard,
      dinersClubCard: dinersClubCard,
      unionpayCard: unionpayCard,
      directDebitCard: directDebitCard,
      paymentTransactions: [],
    }
  },

  components: {
    TipsIcon,
  },

  methods: {
    ...mapActions("paymentRequest", ["makePayment"]),
    getChipColor(status) {
      let color = "";

      switch(status) {
        case "Successful":
          color = "success";
          break;
        case "Processing":
          color = "primary";
          break;
        case "Scheduled":
          color = "neutral";
          break;
        case "Applied":
        case "Failed":
        case "Missed":
          color = "error";
          break;
        default:
          color = "neutral";
          break;
      }

      return color;
    },

    confirmPayment(transaction) {
      this.$vs.loading();

      const data = {
        linkedTo: transaction._id,
        amount: transaction.amount,
        selectedOption: "linked",
        paymentRequestId: this.paymentRequest._id
      };

      const payload = {
        data: data,
        config: {
          header: {
            "Content-Type": "application/json",
          },
        },
      };

      this.makePayment(payload).then((result) => {
        this.$vs.loading.close();
        if (result.data.status == 422) {
          this.showToastMessage("Payment failed", result.data.message, "error");
          this.$vs.loading.close();
        } else {
          this.showToastMessage("Payment processed", `Your payment of ${transaction.amountFormatted} was processed successfully.`, "success");
          this.reloadContent();
        }
      }).catch((ex) => {
        this.showToastMessage("Payment failed", ex.response.data.message, "error");
        this.$vs.loading.close();
      });
    },

    getCardType(cardType) {
      let card = "";

      switch (cardType) {
        case "visa":
          card = this.visaCard;
          break;

        case "mastercard":
          card = this.masterCard;
          break;

        case "amexCard":
          card = this.amexCard;
          break;

        case "unionpay":
          card = this.unionunionpayCardpay;
          break;

        default:
          card = this.visaCard;
          break;
      }

      return card;
    },

    getExpiredYear(date) {
      if (!date) {
        return null;
      }
      return `${date.month}/${date.year.substring(2, 4)}`;
    },

    getAccountNumber(accNum) {
      accNum = accNum.trim().split(" ").join("");
      return accNum.slice(0, accNum.length - 4).replace(/./g, "*")+ accNum.slice(accNum.length - 4);
    },

    hanldeRetryPayment(transaction, index) {
      this.paymentTransactions[index].showConfirmButton = !transaction.showConfirmButton;
      this.paymentTransactions[index].showRetryButton = !transaction.showRetryButton;
      this.$forceUpdate();
    },

    handleDisplayChild(index) {
      for (let i = 0; i < this.paymentTransactions.length; i++) {
        if (index == i) {
          this.paymentTransactions[i].showChild = !this.paymentTransactions[i].showChild;
        } else {
          this.paymentTransactions[i].showChild = false;
        }

        this.paymentTransactions[i].showConfirmButton = false;
        this.paymentTransactions[i].showRetryButton = true;
      }
      this.$forceUpdate();
    }
  },

  computed: {
    defaultPaymentMethod() {
      return this.customerPaymentMethods.find(item => item.default);
    },
  },

  mounted() {
    this.paymentTransactions = JSON.parse(JSON.stringify(this.transactions));
    this.paymentTransactions = this.paymentTransactions.map(item => {
      item.showRetryButton = true;
      item.showConfirmButton = false;
      item.hasChild = item.paymentButton || (item.linkedTransactions && item.linkedTransactions.length);
      item.showChild = false;
      return item;
    });
  }
}
</script>

