<template>
  <div v-if="readyToRender">
    <div class="ds-wrapper md:w-8/12 md:max-w-2lg md:mx-auto mt-20 sm:mt-30">
      <p class="text-body text-sm">Payment ID</p>
      <h1 class="text-primary mb-6">{{ paymentRequestDetail.paymentRequestId }}</h1>

      <Header
        v-if="readyToRender"
        :paymentRequest="paymentRequestDetail"
        :showChangePaymentMethodPopUp="openChangePaymentPopup"
        :reloadContent="reloadContent"
        :key="headerKey"
        :prWithPartnerDetail="prWithPartnerDetail"
        :legals="legals"
      />
      <change-payment-method v-if="readyToRender" :paymentRequest="paymentRequestDetail" @reloadContent="reloadContent" :legals="legals"></change-payment-method>
      <Transactions v-if="readyToRender" :paymentRequest="paymentRequestDetail" :reloadContent="reloadContent" :transactions="paymentRequestDetail.transactions" :customerPaymentMethods="paymentRequestDetail.customerPaymentMethods" />
      <RequestDetails
        v-if="readyToRender"
        :paymentRequest="paymentRequestDetail"
        :isChangeOpen="openChangePaymentPopupFromPayment"
        :reloadContent="reloadContent"
        :prWithPartnerDetail="prWithPartnerDetail"
        :prId="prId"
        :readyToRender="readyToRender"
      />
      <AgreementSummary v-if="readyToRender" :paymentRequest="paymentRequestDetail" :privacy="privacy"></AgreementSummary>
    </div>
  </div>
</template>
<script>
import { mapActions } from "vuex";

import AdditionalInformation from "./AdditionalInformation.vue";
import AgreementSummary from "../components/AgreementSummary.vue";
import ChangePaymentMethod from "../components/ChangePaymentMethod.vue";
import Header from "./Header.vue";
import RequestDetails from "./RequestDetails.vue";
import Transactions from "./Transactions.vue";

export default {
  components: {
    AdditionalInformation,
    AgreementSummary,
    ChangePaymentMethod,
    Header,
    RequestDetails,
    Transactions,
  },

  data() {
    return {
      headerKey: 1,
      paymentRequestDetail: "",
      currentPlan:{},
      readyToRender: false,
      openChangePaymentPopupFromPayment: false,
      prId: "",
      prWithPartnerDetail: "",
      legals: {},
      privacy: "",
    };
  },

  methods: {
    ...mapActions("customer", ["paymentRequestDetailByPRID"]),
    ...mapActions("card", ["getProductCards"]),
    ...mapActions("customer", ["fetchTermsAndConditions"]),

    reloadContent() {
      this.readyToRender = false;
      this.headerKey++;
      this.fetchDetail(`PR-${this.$route.params.prId}`);
    },

    openChangePaymentPopup(isOpen) {
      this.openChangePaymentPopupFromPayment = true
    },

    async fetchDetail(id) {
      this.$vs.loading();
      await this.paymentRequestDetailByPRID(id).then(async (result) => {
        this.paymentRequestDetail = result.data.data;
        this.currentPlan = this.paymentRequestDetail.selectedPaymentPlan;
        this.privacy = this.paymentRequestDetail.privacy;
        this.$vs.loading.close();
        localStorage.removeItem("deletedOptions");
        await this.getLegals();

        this.readyToRender = true;
      }).catch((ex) => {
        this.$vs.loading.close();
      });
    },

    async getLegals() {
      await this.fetchTermsAndConditions().then(res => {
        this.legals = res.data.data;
      });
    },
  },

  mounted() {
    if (!this.accessToken) {
      this.$router.push({ name: "Authenticate" });
      return;
    }

    this.prId = `PR-${this.$route.params.prId}`;
    this.fetchDetail(this.prId);
  },

  computed:{
    accessToken() {
      return localStorage.getItem("accessToken");
    },
  },
};
</script>

